.artists {
  margin: 3rem auto;
  text-align: center;
  &__title {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .1rem;
    text-transform: uppercase;
    margin-bottom: 3rem; }
  &__list {
    display: flex;
    justify-content: center;
    > * {
      margin: 1rem;
      // @include mobile
 }      //   margin: 1rem
    @include mobile {
      flex-direction: column; } } }
