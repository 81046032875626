.artist-card {
  @include flex;
  flex-direction: column;
  padding: 1rem;
  width: 300px;
  min-height: 450px;
  @include mobile {
    width: 90%; }


  border: 2px solid $c-grey;
  &:hover {
    border: 2px solid $c-white;
    .artist-card__btn {
      background: $c-white;
      border: 1px solid $c-white;
      color: $c-black-logo; } }

  &__image {
    max-width: 100%;
    height: 300px;
    object-fit: cover; }

  &__name {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600; }

  &__btn {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    cursor: pointer;
    @include tablet {
      width: 100%;
      margin-top: .5rem; }
    text-align: center;
    padding: 1rem;
    border: 1px solid $c-grey;
    &:hover {
      background: $c-white;
      border: 1px solid $c-white;
      color: $c-black-logo; } } }
