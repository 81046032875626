.footer {
  margin-top: auto;
  padding: 1.5rem;
  width: 100%;
  background: $c-black-logo;

  &__center {
    text-align: center;
    @include mobile {
      margin: 2rem; } }

  &__credits {
    text-align: right;
    margin-top: 1rem;
    @include mobile {
      margin-top: 2.5rem; } }

  &__menu {
    margin-bottom: 1.5rem;
    @include center;
    * {
      text-transform: uppercase;
      font-size: 1.5rem; }
    > * {
      &:not(:first-child) {
        margin-left: 1rem; } } }

  &__links {
    @include center;
    margin: 2rem 0; }

  &__link {
    &:not(:first-child) {
      margin-left: 3rem;
      @include mobile {
        margin-left: 2rem; } }
    border: none !important;
    svg {
      // width: 3.4rem
      height: 1.6rem; }

    &-apple, &-vk, &-spotify, &-tiktok {
      path {
        fill: $c-grey-light; }
      &:hover {
        path {
          fill: $c-light; } } }
    &-youtube, &-instagram {
      path {
        stroke: $c-grey-light; }
      &:hover {
        path {
          stroke: $c-light; } } } } }
